import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
import { ApiService } from '../../inicio/services/api.service';
import { ActivatedRoute } from '@angular/router';
import Phaser from 'phaser';
// import { interval } from 'rxjs';
declare var $: any;

var ContenedorGeneralGlobal;
var PantallaPresentacionGlobal;
var Principal;
var PantallaGlobalGlobal;
var GeneradorRespiracionCirculoGlobal;
var GeneradorRespiracionTrianguloGlobal;
var GeneradorRespiracionOchoGlobal;

@Component({
  selector: 'app-selector-estudiantes',
  templateUrl: './selector-estudiantes.component.html',
  styleUrls: ['./selector-estudiantes.component.css']
})
export class SelectorEstudiantesComponent implements OnInit {

  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  PuedoApretarBoton = true;
  constructor(public global:GlobalService, private route: ActivatedRoute, public api:ApiService) {
    Principal = this;
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#09002d',
      scene: [PantallaPresentacion, PantallaGlobal, ContenedorGeneral]
    };
    this.route.params.subscribe((params:any) => {
        console.log(params)
        this.api.getCursosInstitucion(params).then((reponse:any)=>{
          Principal.cursos  = reponse.CURSOS;
          Principal.params = params;
        })

        this.iniciar();
      });
  }

  ngOnInit(): void {
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Selector de estudiantes"}, '*');
    }
  async iniciar(){

  
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #09002d;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)

    await this.global.offLoading(2500);
    $("body").css("background-color", "#09002d");
    console.log("listo");
  }
  capitalizarPalabras(texto) {
    let palabras = texto.toLowerCase().split(' ');
    for (let i = 0; i < palabras.length; i++) {
      palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }
    let resultado = palabras.join(' ');
    return resultado;
  }
}




class PantallaGlobal extends Phaser.Scene {

  constructor() {
    super({ key: 'PantallaGlobal' });
    PantallaGlobalGlobal = this;
  }
  preload() {
    this.load.image('sonidoE', 'assets/images/aplicativos/selector-estudiantes/sonidoE.png');
    this.load.image('sonidoA', 'assets/images/aplicativos/selector-estudiantes/sonidoA.png');
  }
  cargando;
  create(){
    let boton = this.add.sprite(0,50,"sonidoE").setScale(.8).setInteractive({ useHandCursor:true });
    let botonActivo = "E";
    boton.x=this.scale.width-50;

    const gifElement = document.createElement('img');
    console.log(Principal.api.OrigenIframe)
    gifElement.src = 'assets/images/cargando_todo.gif';
    // if(Principal.api.OrigenIframe == 'juegoeduca'){
    //   gifElement.src = 'assets/images/cargando_je.gif';
    // }else if(Principal.api.OrigenIframe == 'inclutics'){
    //   gifElement.src = 'assets/images/cargando_in.gif';
    // }else if(Principal.api.OrigenIframe == 'letrapps'){
    //   gifElement.src = 'assets/images/cargando_lt.gif';
    // }else if(Principal.api.OrigenIframe == 'tabulatest'){
    //   gifElement.src = 'assets/images/cargando_TBT.gif';
    // }else{
    //   gifElement.src = 'assets/images/cargando_TBT.gif';      
    // }
    // gifElement.src = 'assets/images/cargando_in.gif';
    // // Colocar el elemento en una posición específica (ajusta según tus necesidades)
    // gifElement.style.position = 'absolute';
    // gifElement.style.top = '0px';
    // gifElement.style.left = '0px';

    // Agregar el elemento al cuerpo del documento
    document.body.appendChild(gifElement);
    var cargando = this.add.dom(0 , 400, gifElement).setOrigin(.5).setScale(.5).setAlpha(0);
    cargando.x = this.scale.width/2 - 440;
    this.cargando = cargando

    boton.on('pointerdown', function (pointer) {
      boton.x = boton.x - 5;
      boton.setScale(.76);
      setTimeout(()=>boton.setScale(.8), 100);
      setTimeout(()=>boton.x = boton.x + 5, 100);
      if(botonActivo==="E"){
        botonActivo = "A";
        this.sound.mute = true;
        boton.setAlpha(.8)
      }
      else{
        botonActivo = "E";
        boton.setAlpha(1)
        this.sound.mute = false;
      }

      boton.setTexture("sonido"+botonActivo);
      

      // setTimeout(()=>this.scene.setAlpha(0), 150);
    }, this);


  }
}
class PantallaPresentacion extends Phaser.Scene {

  constructor() {
    super({ key: 'PantallaPresentacion' });
    PantallaPresentacionGlobal = this;
  }
  preload() {

    // for(let i=0; i <= 10; i++) this.load.image('e'+i, 'assets/images/aplicativos/ruidometro/estrellas_'+i+'.png');


    this.load.image('base', 'assets/images/aplicativos/selector-estudiantes/fondo.png');
    this.load.image('a_jugar', 'assets/images/aplicativos/selector-estudiantes/comenzar.png');

    let i  = 2;
    // this.load.image('jiro_e', 'assets/images/aplicativos/ruidometro/jiro_'+i+'.png');
    // this.load.image('fiu_e', 'assets/images/aplicativos/ruidometro/fiu_'+i+'.png');

    this.load.image('ayuda', 'assets/images/aplicativos/selector-estudiantes/ayuda.png');
    this.load.image('logo', 'assets/images/aplicativos/respirometro/flor1.png');

    this.load.audio('slide1', 'assets/images/aplicativos/selector-estudiantes/slide1.mp3');


    this.load.image('base_palitos', 'assets/images/aplicativos/selector-estudiantes/palitos.png');
    this.load.image('base_atras_palitos', 'assets/images/aplicativos/selector-estudiantes/palitos2.png');

    this.load.image('i_base_palitos', 'assets/images/aplicativos/selector-estudiantes/i_palitos.png');
    this.load.image('i_base_atras_palitos', 'assets/images/aplicativos/selector-estudiantes/i_palitos2.png');

    for(let i=1; i <= 24; i++) this.load.image('palito_'+i, 'assets/images/aplicativos/selector-estudiantes/pg_'+i+'.png');

    // for(let i=1; i <= 6; i++) this.load.image('palo_'+i, 'assets/images/aplicativos/selector-estudiantes/palos/palo_'+i+'.png');
    for(let i=1; i <= 24; i++) this.load.image('mono_'+i, 'assets/images/aplicativos/selector-estudiantes/monos/Palitos preguntones v.3-'+i+'.png');
    
    this.load.atlas('flares', 'assets/assets/particles/flares.png', 'assets/assets/particles/flares.json');

  }
  resize (gameSize, baseSize, displaySize, resolution)
  {
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  create(){
    this.scale.on('resize', this.resize, this);
    this.scale.on('orientationchange', (e: string) => {
      switch(e) {
          case 'portrait-primary':
              break;
          case 'landscape-primary':
              break;
          default:  
      }
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
    });

    this.scene.moveBelow(this, ContenedorGeneralGlobal);
    // this.scene.moveBelow(this, PantallaGlobalGlobal);

    this.scene.launch(PantallaGlobalGlobal)


    let base = this.add.image(-2,0,"base").setOrigin(0).setScale(1).setAlpha(.8);

    // this.add.image(this.scale.width/2,130,"logo").setOrigin(0.5).setScale(1).setAlpha(1);

    // this.add.image(500,600,"fiu_e").setOrigin(0.5).setScale(.45).setAlpha(1);
    // this.add.image(1400,600,"jiro_e").setOrigin(0.5).setScale(.7).setAlpha(1);

    let inicio = this.add.sprite(this.scale.width/2,620,"a_jugar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
    
    let anterior = -1;

    let slide1 = this.sound.add("slide1");

    let cantidad_de_palitos = 0;
    let contador_de_palitos = 0;
    function getRandomNumber(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    let base_palitos = this.add.sprite(this.scale.width/2,400,"i_base_palitos").setScale(1).setAlpha(1).setDepth(2).setOrigin(.5);
    let base_palitos2 = this.add.sprite(this.scale.width/2,400,"i_base_atras_palitos").setScale(1).setAlpha(1).setDepth(0).setOrigin(.5);

    let contenedor_palitos = this.add.container(1100,250).setDepth(1);
    contenedor_palitos.setSize(base_palitos.width-5, base_palitos.height/2);
    contenedor_palitos.x = base_palitos.x+120;
    for (let i = 0; i < 20; i++) {
      // const option = selectElement.options[i];
      
        cantidad_de_palitos++;
        contador_de_palitos++;
        if(cantidad_de_palitos>24) cantidad_de_palitos=1;
        let x = getRandomNumber(0,(base_palitos.width)-90);

        let _imageGroup_:any = this.add.image(0,0,"palito_"+cantidad_de_palitos).setScale(1);
        let imageGroup:any = this.add.container(x-200,getRandomNumber(-5, 50)).setScale(.4);
        imageGroup.setSize(_imageGroup_.width, _imageGroup_.height);
        imageGroup.add(_imageGroup_);

        imageGroup.tw = this.tweens.add({
          targets: imageGroup,
          duration: Phaser.Math.Between(100, 300), // Duración de la transición en milisegundos
          y: "+=5",
          delay: Phaser.Math.Between(0, 100),
          repeat: -1,
          yoyo: true,
          ease: 'Power2',
        });

        contenedor_palitos.add(imageGroup);

    }

    this.add.text(0,30, "Selector de Estudiantes" , {
      font: "bold 85px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true);
    // this.add.text(0,300, "bla bla bla" , {
    //   font: "bold 30px Arial",
    //   align: "center",
    //   color: "white",
    //   shadow: {offsetX: 2.5,offsetY: 2.5,color: 'black',blur: 1,stroke: true,fill: true},
    //   // backgroundColor: "red",
    //   fixedWidth: this.scale.width
    // }).setVisible(true);


    let intLink = this.add.text(0,850, "Un producto con ❤ de integritic.cl" , {
      font: "20px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true).setInteractive({useHandCursor:true});

    intLink.on('pointerdown', function (pointer) {
      if(!Principal.PuedoApretarBoton) return;
      window.open("https://www.integritic.cl", '_blank');
    }, this);

    // ContenedorGeneralGlobal.micEncendido = false;

    let ayuda = this.add.sprite(this.scale.width/2,750,"ayuda").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });

    inicio.on('pointerdown', function (pointer) {

      if(!Principal.PuedoApretarBoton) return;
      inicio.x = inicio.x - 5;
      inicio.setScale(.95);
      setTimeout(()=>inicio.setScale(1), 100);
      setTimeout(()=>inicio.x = inicio.x + 5, 100);
      setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
      setTimeout(()=>this.scene.pause(), 150);
      setTimeout(()=>this.scene.setVisible(false), 150);

      slide1.play();
      // setTimeout(()=>this.scene.setAlpha(0), 150);
    }, this);

    ayuda.on('pointerdown', function (pointer) {
      if(!Principal.PuedoApretarBoton) return;
      ayuda.x = ayuda.x - 5;
      ayuda.setScale(.48);
      setTimeout(()=>ayuda.setScale(.5), 100);
      setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
      Principal.PuedoApretarBoton = false;
      Principal.global.alerta.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "500",
        title: 'Instrucciones:',
        html: `<div style="font-size: 20px;">
        Esta función le permitirá poder elegir estudiantes de cualquier curso automáticamente, emulando los tradicionales palitos preguntones de forma virtual. ¡Es súper entretenido!</div>`,
        text: ``
      }).then(function () {
        Principal.PuedoApretarBoton = true;
      });
    }, this);

  }
}


class ContenedorGeneral extends Phaser.Scene {

  constructor() {
    super({ key: 'ContenedorGeneral' });
    ContenedorGeneralGlobal = this;
    // PantallaInicialGlobal = this;
  }
  preload() {
    this.load.image('continuar', 'assets/images/aplicativos/selector-estudiantes/continuar.png');
    this.load.image('volver', 'assets/images/aplicativos/selector-estudiantes/volver.png');
    this.load.image('volver2', 'assets/images/aplicativos/selector-estudiantes/volver2.png');
    this.load.image('otro', 'assets/images/aplicativos/selector-estudiantes/otro.png');
    this.load.image('cambio_curso', 'assets/images/aplicativos/selector-estudiantes/cambio_curso.png');
    this.load.image('seleccionar', 'assets/images/aplicativos/selector-estudiantes/seleccionar.png');
    this.load.image('todos', 'assets/images/aplicativos/selector-estudiantes/todos.png');
    this.load.image('ayuda2', 'assets/images/aplicativos/selector-estudiantes/ayuda2.png');
    this.load.image('destodos', 'assets/images/aplicativos/selector-estudiantes/destodos.png');
    this.load.image('cargando', 'assets/images/cargando_je.gif');

    this.load.audio('sonido_selector', 'assets/images/aplicativos/selector-estudiantes/sonido_selector.mp3');
    this.load.audio('slide', 'assets/images/aplicativos/selector-estudiantes/slide.mp3');


    // this.load.audio('slide1', 'assets/images/aplicativos/selector-estudiantes/slide1.mp3');
    this.load.audio('slide2', 'assets/images/aplicativos/selector-estudiantes/slide2.mp3');
    this.load.audio('slide3', 'assets/images/aplicativos/selector-estudiantes/slide3.mp3');
    this.load.audio('slide4', 'assets/images/aplicativos/selector-estudiantes/slide4.mp3');
    this.load.audio('slide5', 'assets/images/aplicativos/selector-estudiantes/slide5.mp3');
    this.load.audio('slide6', 'assets/images/aplicativos/selector-estudiantes/slide6.mp3');
    this.load.audio('slide7', 'assets/images/aplicativos/selector-estudiantes/slide7.mp3');

    this.load.atlas('flares', 'assets/assets/particles/flares.png', 'assets/assets/particles/flares.json');

  }
  Camara;
  cantEstudiantes;
  create() {
    $('#btn-volver').addClass('hidden');
    this.sound.pauseOnBlur = false;
    this.cameras.main.setBounds(0, 0, 40000, 40000);
    this.cameras.main.setZoom(1);
    let base = this.add.image(0,0,"base").setOrigin(0).setScale(1).setAlpha(1);

    for(let i=1;i < 10; i++) this.add.image((base.width*i)-1,0,"base").setOrigin(0).setScale(1).setAlpha(1);
      // Crear el jugador
      this.Camara = this.add.sprite(400, 300, 'player').setAlpha(0);

    var curso = document.createElement('select');
    curso.id = "text-cant";
    curso.style.border = "none";
    curso.style.borderRadius = "10px";
    curso.style.height = '100px';
    curso.style.width = '400px';
    curso.style.fontSize = '25px';
    curso.style.textAlign = 'center';
    

    var letra = document.createElement('select');
    letra.id = "text-cant2";
    letra.style.border = "none";
    letra.style.borderRadius = "10px";
    letra.style.height = '100px';
    letra.style.width = '400px';
    letra.style.fontSize = '25px';
    letra.style.textAlign = 'center';
    
    // var option1 = document.createElement('option');
    //   // option1.value = "false";
    //   option1.text = "Seleccione..";
    //   // option1.disabled = true;
    //   option1.selected = true;
      
    //   curso.add(option1);
      // letra.add(option1);


      
    let option3 = document.createElement('option');
    option3.value = "false";
    option3.text = "Seleccione...";
    option3.selected = true;
    option3.disabled = true;
    curso.add(option3);
    
    let option2 = document.createElement('option');
    option2.value = "false";
    option2.text = "Seleccione...";
    option2.selected = true;
    option2.disabled = true;
    letra.add(option2);


    Principal.cursos.filter(a=>a.ESTADO.toString() === "1" && a.CODIGO.toString() != "0" ).sort((a,b)=>a.ORDEN-b.ORDEN).map(a=>{
      
      var text_curso = a.NOMBRE;
      if(Principal.params.plataforma.includes("TBT_B2C")){
        text_curso = "Matrícula";
      }

      let option1 = document.createElement('option');
      option1.value = a._id;
      option1.text = text_curso;
      option1.selected = false;
      curso.add(option1);
    })
    

    //   let seleccionado = Principal.cursos.filter(a=>a._id ===  $(curso).val() )[0];
    // seleccionado.DETALLE.map(a=>{
    //   let option1 = document.createElement('option');
    //   option1.value = a;
    //   option1.text = a;
    //   option1.selected = false;
    //   letra.add(option1);
    // })
 
    
    curso.addEventListener("change", function() {
      letra.innerHTML = '';
      
      let option1 = document.createElement('option');
      option1.value = "false";
      option1.text = "Seleccione...";
      option1.selected = true;
      option1.disabled = true;
      letra.add(option1);

      if($(curso).val()==="false") return;
      let seleccionado = Principal.cursos.filter(a=>a._id ===  $(curso).val() )[0];
      seleccionado.DETALLE.map(a=>{
        var text_letra = a;
        if(Principal.params.plataforma.includes("_B2C")){
          text_letra = "Todos los Estudiantes";
        }

        let option1 = document.createElement('option');
        option1.value = a;
        option1.text = text_letra;
        option1.selected = false;
        letra.add(option1);
      })
    });

    letra.addEventListener("change", function() {});

    document.body.appendChild(curso);
    document.body.appendChild(letra);

    let limite = base.width*2;
    var cursoEl:any = this.add.dom(limite-(Principal.params.plataforma.includes("LT_B2C")?200:450), 400, curso).setOrigin(0);
    var letraEl:any = this.add.dom(limite+50, 400, letra).setOrigin(0);



    let limite3 = base.width*4;

    var selectElement:any = document.createElement('select');
    selectElement.id = "text-cant3";
    selectElement.style.fontFamily = "C_Arlon_Regular";
    selectElement.style.border = "none";
    selectElement.style.borderRadius = "10px";
    selectElement.style.height = '500px';  // Ajusta la altura según sea necesario
    selectElement.style.width = '1000px';
    selectElement.style.fontSize = '30px';  // Ajusta el tamaño de fuente según sea necesario
    selectElement.style.textAlign = 'left';
    selectElement.multiple = true;  // Habilita la selección múltiple

    let base_palitos2 = this.add.sprite(limite3+500,601,"base_atras_palitos").setScale(1).setAlpha(1);
    let base_palitos = this.add.sprite(limite3+500,600,"base_palitos").setScale(1).setAlpha(1);

    let self = this; 

    document.body.appendChild(selectElement);
    var inputEl:any = this.add.dom(limite3-900, 100, selectElement).setOrigin(0);
    inputEl.addListener('click').on('click', function (event) {
        self.updateSelectedOptions(selectElement);
    });

    selectElement.addEventListener('change', () => {
      this.updateSelectedOptions(selectElement);
    });

    this.cameras.main.startFollow(this.Camara);
    this.cameras.main.centerOn(0, 0);

      // cargando.x -= cargando.width; 
      
      // (base.width*3),450
      if(Principal.params.plataforma.includes("LT_B2C")){
        $("#text-cant2").addClass("hidden");
      }else{
        $("#text-cant2").removeClass("hidden");
      }

      let estudiantes = {};
      
      let continuar = this.add.sprite(cursoEl.x+(Principal.params.plataforma.includes("LT_B2C")?200:450),650,"continuar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
      continuar.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        
        continuar.x = continuar.x - 5;
        continuar.setScale(.95);
        setTimeout(()=>continuar.setScale(1), 100);
        setTimeout(()=>continuar.x = continuar.x + 5, 100);

        if(Principal.params.plataforma.includes("LT_B2C")){
          if($(curso).val() == null){
            Principal.PuedoApretarBoton = false;
            Principal.global.alerta.fire({
              allowOutsideClick: false,
              allowEscapeKey: false,
              width: "500",
              title: '',
              html: `<h3>Debe seleccionar un curso<br>para continuar.</h3>`,
              text: ``
            }).then(function () {
              Principal.PuedoApretarBoton = true;
            });
            return;
          }
        }else{
          if($(curso).val() == null || $(letra).val() === null){
            Principal.PuedoApretarBoton = false;
            Principal.global.alerta.fire({
              allowOutsideClick: false,
              allowEscapeKey: false,
              width: "500",
              title: '',
              html: `<h3>Debe seleccionar un curso<br>para continuar.</h3>`,
              text: ``
            }).then(function () {
              Principal.PuedoApretarBoton = true;
            });
            return;
          }
        }

        var letra_curso = Principal.params.plataforma.includes("LT_B2C")?" ":Principal.params.plataforma.includes("TBT_B2C")?"B2C":$(letra).val();
        
        // Principal.global.showLoading();
        if(Principal.params.plataforma.includes("TBT_B2C")){
          nombreCurso.setText($("#text-cant :selected").text());
        }else{
          nombreCurso.setText($("#text-cant :selected").text()+ " "+letra_curso);
        }
        

        slide5.play();
        

        Principal.api.getEstudiantesInstitucion({curso: $(curso).val(), letra: letra_curso, promocion: Principal.params.promocion, plataforma:Principal.params.plataforma}).then(response=>{
          recargarTabla(response, true);
        });
        let Datos = [];
        let recargarTabla = (response, movimientoDeCamara)=>{
          if(!response) response = Datos;
            else Datos = response;
          selectElement.innerHTML = '';
          sePuedeSeleccionar = true;

          let cont_ = 0;

          response.ALUMNOS.sort((a,b)=>a.ALUMNOCURSO.NUMERO_LISTA-b.ALUMNOCURSO.NUMERO_LISTA).map(a=>{
              let option1 = document.createElement('option');
              option1.value = a._id;
              let disabled = a.ALUMNOCURSO.ESTADO.toString() === "1" ? false: true;
              estudiantes[a._id] = [a.NOMBRES,a.APELLIDOPATERNO, a.APELLIDOMATERNO].join(" ");

              var n_lista = a.ALUMNOCURSO.NUMERO_LISTA;
              cont_++;
              if(Principal.params.plataforma.includes("_B2C")){
                n_lista = cont_;
              }

              // option1.text = a.ALUMNOCURSO.NUMERO_LISTA+".- "+ [a.NOMBRES,a.APELLIDOPATERNO, a.APELLIDOMATERNO].join(" ")+" "+(disabled?"(RETIRADO)":"")+(a.DIAGNOSTICO?("• "+a.DIAGNOSTICO):"");
              option1.text = Principal.capitalizarPalabras(n_lista+".- "+ [a.NOMBRES,a.APELLIDOPATERNO, a.APELLIDOMATERNO].join(" ")+" "+(disabled?"(RETIRADO)":""));
              option1.selected = false;
              option1.disabled = disabled;
              selectElement.add(option1);
              $(option1).mousedown(function(e) {
                e.preventDefault();
                var $select = $(this).parent();
                var scrollPosition = $select.scrollTop();
                $select.css("overflow-y", "hidden");
                $(this).prop('selected', !$(this).prop('selected'));
                setTimeout(function() {
                    $select.scrollTop(scrollPosition);
                    $select.css("overflow-y", "auto");
                }, 0);
                return false;
            })
          });

          
          
          this.updateSelectedOptions(selectElement);

          if(movimientoDeCamara)
          this.tweens.add({
            targets: this.Camara,
            duration: 500, // Duración de la transición en milisegundos
            delay: 2000,
            onDelay: e=>{
              slide5.play();
              PantallaGlobalGlobal.cargando.setAlpha(0);
            },
            x: (base.width*4),
            onComplete: e=>{
              
              if(response.ALUMNOS.length === 0){
                Principal.PuedoApretarBoton = false;
                Principal.global.alerta.fire({
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  width: "500",
                  title: 'Opps',
                  html: `No hay estudiantes en el curso seleleccionado.`,
                  text: ``
                }).then(function () {
                  Principal.PuedoApretarBoton = true;
                });;
                
              } 
            },
            ease: 'Power2',
          });
        }

        
        this.tweens.add({
          targets: this.Camara,
          duration: 500, // Duración de la transición en milisegundos
          delay: 100,
          x: (base.width*3),
          ease: 'Power2',
          onComplete: e=>{

            this.tweens.add({
              targets: PantallaGlobalGlobal.cargando,
              duration: 200,
              alpha: 1,
              ease: 'Power2'
            });


            
          }
        });

        
        // console.log($(curso).val(),$(letra).val())


        

        // setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
        // setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
      }, this);
          
      // this.tweens.add({
      //   targets: this.Camara,
      //   duration: 1000, // Duración de la transición en milisegundos
      //   delay: 3000,
      //   x: (base.width*3)/2,
      //   ease: 'Power2',
      // });
      this.contenedorPalitos = this.add.container(base_palitos.x+50, base_palitos.y);
      this.contenedorPalitos.setSize(base_palitos.width-100, base_palitos.height);


      

      let nombreCurso = this.add.text(base_palitos.x,base_palitos.y-5, "" , {
        font: "bold 45px C_Arlon_Regular",
        align: "center",
        color: "#FAF4E4",
        // shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
        // backgroundColor: "red",
        fixedWidth: base.width
      }).setVisible(true).setOrigin(.5).setDepth(4);


      base_palitos.setDepth(2);
      this.contenedorPalitos.setDepth(1);
      
      this.tweens.add({
        targets: this.Camara,
        duration: 1000, // Duración de la transición en milisegundos
        delay: 0,
        x: (base.width*2),
        ease: 'Power2',
      });

      let nombreSeleccionado:any;


      this.cantEstudiantes = this.add.text(limite3-200,70, "Estudiantes Seleccionados: " , {
        font: "bold 15px Arial",
        align: "right",
        color: "white",
        shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
        // backgroundColor: "red",
        fixedWidth: 300
      }).setVisible(true).setScale(1).setAlpha(1);

      let limite4 = base.width*5;

      let palitoSeleccionado;
      
      let btnVolverCurso = this.add.sprite(limite3-870,50,"volver2").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3).setAlpha(1);
      btnVolverCurso.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        btnVolverCurso.x = btnVolverCurso.x - 5;
        btnVolverCurso.setScale(.95);
        setTimeout(()=>btnVolverCurso.setScale(1), 100);
        setTimeout(()=>btnVolverCurso.x = btnVolverCurso.x + 5, 100);
        // setTimeout(()=>btnVolverCurso.x = btnVolverCurso.x + 5, 100);

        this.tweens.add({
          targets: this.Camara,
          duration: 1000, // Duración de la transición en milisegundos
          delay: 300,
          onDelay: e=>{
            slide2.play();
          },
          x: (base.width*2),
          ease: 'Power2',
        });

      },this);

      let sePuedeSeleccionar = false;

     

      // this.input.on('pointerdown', pointer => {



      // });

      
      let SeleccionDeEstudiante = ()=>{
        if(this.seleccionandoPalito) return;
        let array = this.contenedorPalitos.list.filter(A=>!A.mostrado);
        if(array.length < 1 ){
          Principal.PuedoApretarBoton = false;
          Principal.global.alerta.fire({
            allowOutsideClick: false,
        allowEscapeKey: false,
            width: "500",
            title: 'Opps',
            html: `Debe seleccionar al menos 1 estudiante.`,
            text: ``
          }).then(function () {
            Principal.PuedoApretarBoton = true;
          });;
          return;
        }

        nombreSeleccionado = this.add.text(0,0, "" , {
          font: "bold 35px Arial",
          align: "left",
          color: "black",
          shadow: {offsetX: .5,offsetY: .5,color: 'white',blur: 1,stroke: true,fill: true},
          // backgroundColor: "red",
          fixedWidth: 1000
        }).setVisible(true).setScale(1).setAlpha(0);

        


        let diffX= 500;
        const indiceAleatorio = Phaser.Math.Between(0, array.length - 1);
        
        let palo_seleccionado = array[indiceAleatorio];
        this.contenedorPalitos.add(nombreSeleccionado);
        nombreSeleccionado.mostrado = true;
        // nombreSeleccionado.y = nombreSeleccionado.y;
        // nombreSeleccionado.x = nombreSeleccionado.diffX;
        
        nombreSeleccionado.setText(estudiantes[palo_seleccionado.seleccionado]);
        nombreSeleccionado.setAngle(180);
        nombreSeleccionado.setAlpha(0);

        nombreSeleccionado.x = 800;
        nombreSeleccionado.y = 80;

        palo_seleccionado.add(nombreSeleccionado);

        palitoSeleccionado = palo_seleccionado;

        if(!palo_seleccionado) return;


        


        this.seleccionandoPalito = true;

        palo_seleccionado.tw.stop();
        palo_seleccionado.mostrado = true;


        sonido_selector.play();

        
        this.tweens.add({
          targets: [palo_seleccionado],
          duration: 3000, // Duración de la transición en milisegundos
          delay: 300,
          x: 1780,
          y: -200,
          angle: 810+90,
          onComplete:a=>{
            
          },
          ease: 'Power2',
        });


        this.tweens.add({
          targets: seleccionarPalito,
          duration: 100, // Duración de la transición en milisegundos
          delay: 2000,
          alpha: 0,
          // y: "+=70",
          ease: 'Power2',
        });

        this.tweens.add({
          targets: [nombreSeleccionado],
          duration: 500, // Duración de la transición en milisegundos
          delay: 500,
          alpha: 1,
          // x: 1650-diffX,
          // angle: 810-90,
          ease: 'Power2',
        });

        
        
        this.tweens.add({
          targets: [palo_seleccionado],
          duration: 1100, // Duración de la transición en milisegundos
          delay: 1000,
          scale: 1,
          onComplete:s=>{


            const emitter:any = this.add.particles('flares').createEmitter({
              frame: [ 'red', 'yellow', 'green' ],
              x: (base.width*5),
              y: -300,
              lifespan: 4000,
              speed: { min: 150, max: 550 },
              scale: { start: 0.8, end: 0 },
              gravityY: 150,
              blendMode: 'SCREEN',
              // emitting: false
            });
            emitter.explode(300);

            if(array.length > 1){
              // arr.push(btnSeleccionarOtro);
            }
            else{
              setTimeout(A=>{
                Principal.PuedoApretarBoton = false;
                Principal.global.alerta.fire({
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  width: "500",
                  title: 'Opps',
                  html: `No quedan más estudiantes para seleccionar.`,
                  text: ``
                }).then(function () {
                  Principal.PuedoApretarBoton = true;
                });;
              },4000);
            }

            
            this.seleccionandoPalito = false;
          },
          // y: "+=10",
          ease: 'Power2',
        });

        let arr = [btnVolver,btnCursoFinal];
        if(array.length > 1){
          arr.push(btnSeleccionarOtro);
        }
        
        this.tweens.add({
          targets: arr,
          duration: 500, // Duración de la transición en milisegundos
          delay: 4000,
          alpha: 1,
          // y: "+=70",
          ease: 'Power2',
        });


        this.tweens.add({
          targets: [this.Camara],
          duration: 3000, // Duración de la transición en milisegundos
          delay: 300,
          x: (base.width*5),
          ease: 'Power2',
        });
      }
      
      
      let btnSeleccionarOtro = this.add.sprite(limite4,600,"otro").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3).setAlpha(0);
      btnSeleccionarOtro.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        btnSeleccionarOtro.x = btnSeleccionarOtro.x - 5;
        btnSeleccionarOtro.setScale(.95);
        setTimeout(()=>btnSeleccionarOtro.setScale(1), 100);
        setTimeout(()=>btnSeleccionarOtro.x = btnSeleccionarOtro.x + 5, 100);
        sePuedeSeleccionar = false;
        // seleccionarPalito.setAlpha(1);
        this.tweens.add({
          targets: [btnVolver,btnCursoFinal,btnSeleccionarOtro],
          duration: 1000, // Duración de la transición en milisegundos
          // delay: 3000,
          alpha: 0,
          // y: "+=70",
          ease: 'Power2',
          onComplete: e=>{
            SeleccionDeEstudiante();
          }
        });
        slide.play();
        this.tweens.add({
          targets: palitoSeleccionado,
          duration: 2000, // Duración de la transición en milisegundos
          // delay: 3000,
          alpha: 0,
          x: "+=7000",
          ease: 'Power2',
        });

        this.tweens.add({
          targets: this.Camara,
          duration: 1000, // Duración de la transición en milisegundos
          delay: 300,
          x: this.contenedorPalitos.x+500,
          ease: 'Power2',
        });


        

      },this);
      
      let btnCursoFinal = this.add.sprite(limite4-810,50,"cambio_curso").setOrigin(0.5).setScale(.8).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3).setAlpha(0);
      btnCursoFinal.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;

        sonido_selector.stop();

        btnCursoFinal.x = btnCursoFinal.x - 5;
        btnCursoFinal.setScale(.75);
        seleccionarPalito.setAlpha(1);
        setTimeout(()=>btnCursoFinal.setScale(.8), 100);
        setTimeout(()=>btnCursoFinal.x = btnCursoFinal.x + 5, 100);

        this.tweens.add({
          targets: [btnCursoFinal,btnVolver,btnSeleccionarOtro],
          duration: 2000, // Duración de la transición en milisegundos
          // delay: 3000,
          alpha: 0,
          // y: "+=70",
          ease: 'Power2',
        });

        this.tweens.add({
          targets: palitoSeleccionado,
          duration: 2000, // Duración de la transición en milisegundos
          // delay: 3000,
          alpha: 0,
          x: "+=7000",
          ease: 'Power2',
        });

        this.tweens.add({
          targets: this.Camara,
          duration: 1000, // Duración de la transición en milisegundos
          delay: 300,
          onDelay: e=>{
            slide4.play();
          },
          x: (base.width*2),
          ease: 'Power2',
        });

      },this);

      
      let btnVolverLanding = this.add.sprite(limite-900,50,"volver2").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3).setAlpha(1);
      btnVolverLanding.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        
        
        btnVolverLanding.x = btnVolverLanding.x - 5;
        btnVolverLanding.setScale(.95);
        seleccionarPalito.setAlpha(1);
        setTimeout(()=>btnVolverLanding.setScale(1), 100);
        setTimeout(()=>btnVolverLanding.x = btnVolverLanding.x + 5, 100);

        PantallaPresentacionGlobal.scene.resume();
        $('#btn-volver').removeClass('hidden');
        this.tweens.add({
          targets: this.Camara,
          duration: 0, // Duración de la transición en milisegundos
          x: 0,
          ease: "Power2",
          onComplete: d=>{
            PantallaPresentacionGlobal.scene.setVisible(true);
            this.scene.stop();
          }
        });

      },this);
      
      let sonido_selector = this.sound.add("sonido_selector"); 
      let slide = this.sound.add("slide");
      let slide1 = this.sound.add("slide1");
      let slide2 = this.sound.add("slide2");
      let slide3 = this.sound.add("slide3");
      let slide4 = this.sound.add("slide4");
      let slide5 = this.sound.add("slide5");
      let slide6 = this.sound.add("slide6");
      let slide7 = this.sound.add("slide7");
      
      let btnVolver = this.add.sprite(limite4-900,50,"volver2").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3).setAlpha(0);
      btnVolver.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        btnVolver.x = btnVolver.x - 5;
        btnVolver.setScale(.95);
        seleccionarPalito.setAlpha(1);
        setTimeout(()=>btnVolver.setScale(1), 100);
        setTimeout(()=>btnVolver.x = btnVolver.x + 5, 100);
        sePuedeSeleccionar = true;
        this.updateSelectedOptions(selectElement);
        this.tweens.add({
          targets: [btnVolver,btnCursoFinal,btnSeleccionarOtro],
          duration: 2000, // Duración de la transición en milisegundos
          // delay: 3000,
          alpha: 0,
          // y: "+=70",
          ease: 'Power2',
        });
        this.tweens.add({
          targets: palitoSeleccionado,
          duration: 2000, // Duración de la transición en milisegundos
          // delay: 3000,
          alpha: 0,
          x: "+=7000",
          ease: 'Power2',
        });

        this.tweens.add({
          targets: this.Camara,
          duration: 1000, // Duración de la transición en milisegundos
          delay: 300,
          onDelay: e=>{
            slide4.play();
          },
          x: (base.width*4),
          ease: 'Power2',
        });

      },this);



      this.add.text(limite,250, "Seleccione Curso" , {
        font: "bold 75px Arial",
        align: "center",
        color: "white",
        shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
        // backgroundColor: "red",
        fixedWidth: base.width
      }).setVisible(true).setOrigin(.5);


      let destodos = this.add.sprite(limite3-580,650,"destodos").setOrigin(0.5).setScale(1).setAlpha(.5).setInteractive({ useHandCursor: true }).setDepth(3);
      
      destodos.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        destodos.x = destodos.x - 5;
        destodos.setScale(.95);
        setTimeout(()=>destodos.setScale(1), 100);
        setTimeout(()=>destodos.x = destodos.x + 5, 100);
        for (let i = 0; i < selectElement.options.length; i++) selectElement.options[i].selected = false;

        this.updateSelectedOptions(selectElement);

      },this);

      let ayuda = this.add.sprite(limite3-430,650,"ayuda2").setOrigin(0.5).setScale(.7).setAlpha(1).setInteractive({ useHandCursor: true });
      ayuda.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        ayuda.x = ayuda.x - 5;
        ayuda.setScale(.65);
        setTimeout(()=>ayuda.setScale(.7), 100);
        setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
        Principal.PuedoApretarBoton = false;
        Principal.global.alerta.fire({
          allowOutsideClick: false,
        allowEscapeKey: false,
          width: "500",
          title: 'Instrucciones:',
          html: `<div style="font-size: 20px;">
          Puede deseleccionar manualmente los estudiantes que están ausentes para que no aparezcan en los palitos preguntones</div>`,
          text: ``
        }).then(function () {
          Principal.PuedoApretarBoton = true;
        });;
      }, this);

      let todos = this.add.sprite(limite3-800,650,"todos").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3);
      this.destodos = destodos;
      this.todos = todos;
      todos.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        todos.x = todos.x - 5;
        todos.setScale(.95);
        setTimeout(()=>todos.setScale(1), 100);
        setTimeout(()=>todos.x = todos.x + 5, 100);
        for (let i = 0; i < selectElement.options.length; i++) selectElement.options[i].selected = true;

        this.updateSelectedOptions(selectElement);

      },this);


      let seleccionarPalito = this.add.sprite(limite3+500,710,"seleccionar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true }).setDepth(3);
      seleccionarPalito.on('pointerdown', function (pointer) {
        if(!Principal.PuedoApretarBoton) return;
        if(!sePuedeSeleccionar) return;
        
        seleccionarPalito.x = seleccionarPalito.x - 5;
        seleccionarPalito.setScale(.95);
        setTimeout(()=>seleccionarPalito.setScale(1), 100);
        setTimeout(()=>seleccionarPalito.x = seleccionarPalito.x + 5, 100);

        SeleccionDeEstudiante();

      },this);
      


      // Configurar la transición de la cámara
      // this.tweens.add({
      //     targets: this.cameras.main,
      //     duration: 2000, // Duración de la transición en milisegundos
      //     ease: 'Power2',
      //     paused: true // Iniciar en pausa para activar manualmente más tarde
      // });
  }
  contenedorPalitos:any;
  seleccionandoPalito:any=false;
  updateSelectedOptions(selectElement: HTMLSelectElement) {
    // Elimina cualquier indicador anterior
    let cantidad_de_palitos = 0;
    let contador_de_palitos = 0;

    this.contadorEstudiantesSeleccionados = 0;
    this.contadorEstudiantesTotales = 0;
    function getRandomNumber(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    this.contenedorPalitos.removeAll(true);
    let arrColores = [
      [0xFFCC66,0xFAB950,0xF39713],
      // [0xE46DA7 ,0xE762A0, 0xDA2F69],
      [0xC0D56D ,0xAFC941, 0x8C9E26],
      [0xF39770 ,0xF18A67, 0xEA6035],
      
      [0xFEACCA ,0xFE6C9F, 0xFE6C9F],

      [0xFFFFE5 ,0xFEC832, 0xe0af26],
      [0xA7DD14 ,0x516b0b, 0xa2c24e],

    ];

    let contador_colores = 0;

    for (let i = 0; i < selectElement.options.length; i++) {
      const option = selectElement.options[i];
      if(!option.disabled){
        this.contadorEstudiantesTotales++;
      }
      if (option.selected && !option.disabled) {
        option.classList.add('selected-option');
        cantidad_de_palitos++;
        contador_de_palitos++;
        if(cantidad_de_palitos>24) cantidad_de_palitos=1;

        if(contador_colores>(arrColores.length-1)) contador_colores=0;

        let diffx = -500;
        let x = getRandomNumber(0,this.contenedorPalitos.width-70);


        this.contadorEstudiantesSeleccionados++;
        // let palo = this.add.image(0,0,"palo_"+cantidad_de_palitos).setScale(1).setOrigin(1).setAngle(180);
        let mono:any = this.add.image(-50+diffx,-85,"mono_"+cantidad_de_palitos).setScale(1).setOrigin(1).setAngle(180);
        let imageGroup:any = this.add.container(x-190+diffx,getRandomNumber(-350,-200)).setScale(.43);

        // palo.setSize(palo.width*2,palo.height);
        imageGroup.setSize(1000, 40);

        let color = arrColores[contador_colores];
        contador_colores++;
        // const graphics = this.add.graphics();
        // graphics.fillStyle(0xff0000); // Establecer el color de fondo (en este caso, rojo)
        // graphics.fillRect(0, 0, imageGroup.width, imageGroup.height); // Tamaño del fondo

        var backgroundRect = this.add.graphics();
        backgroundRect.fillStyle(color[1], 0.9); // Color azul con opacidad
        backgroundRect.fillRoundedRect(1+diffx, -10, 1350, 130, 30);

        // Crear un rectángulo con bordes redondeados (capa superior)
        var roundedRect = this.add.graphics();

        roundedRect.fillStyle(color[0], 1); // Color rojo sin opacidad
        roundedRect.fillRoundedRect(0+diffx, 0, 1350, 120, 30); // 20 es el radio de los bordes

        
        let agregarC = (h, y)=>{
          var graphics = this.add.graphics();
          // Configuración del rectángulo redondeado
          var x2 = 0+diffx;
          var y = y;
          var width = 1350;
          var height = h;
          var borderRadius = 30;
          var borderColor = color[2]; // Color del borde (verde)
          var borderThickness = 2.5; // Grosor del borde

          // Dibujar el rectángulo redondeado sin relleno
          graphics.lineStyle(borderThickness, borderColor);
          graphics.strokeRoundedRect(x2, y, width, height, borderRadius);

          imageGroup.add(graphics);
        }

        imageGroup.add(backgroundRect);
        // agregarC(130, -10);

        imageGroup.add(roundedRect);
        agregarC(120, 0);
        

        

       
       
       


        
        // imageGroup.add(palo);
        imageGroup.add(mono);

        imageGroup.setAngle(90);
        imageGroup.y += 50;
        imageGroup.x += 500;
        
        imageGroup.mostrado = false;
        imageGroup.seleccionado = option.value;

        imageGroup.tw = this.tweens.add({
          targets: imageGroup,
          duration: Phaser.Math.Between(100, 300), // Duración de la transición en milisegundos
          y: "+=5",
          delay: Phaser.Math.Between(0, 100),
          repeat: -1,
          yoyo: true,
          ease: 'Power2',
        });

        this.contenedorPalitos.add(imageGroup);
        
        
      } else {
        option.classList.remove('selected-option');
      }
    }

    // console.log(this.contadorEstudiantesTotales)
    // console.log(this.contadorEstudiantesSeleccionados)

    if(this.contadorEstudiantesTotales === this.contadorEstudiantesSeleccionados){
      this.destodos.setAlpha(1);
      this.todos.setAlpha(.5);
    } 
    else if(this.contadorEstudiantesSeleccionados === 0){
      this.destodos.setAlpha(.5);
      this.todos.setAlpha(1);
    } 
    else if(this.contadorEstudiantesSeleccionados > 0){
      this.destodos.setAlpha(1);
      this.todos.setAlpha(1);
    } 
    

    this.cantEstudiantes.setText("Estudiantes Seleccionados: "+contador_de_palitos);
  }
  destodos;
  todos;
  contadorEstudiantesSeleccionados=0;
  contadorEstudiantesTotales=0;
  update() {
    // // Mover el jugador con las teclas de flecha
    // if (this.cursors.left.isDown) {
    //     this.Camara.x -= 5;
    // } else if (this.cursors.right.isDown) {
    //     this.Camara.x += 5;
    // }

    // if (this.cursors.up.isDown) {
    //     this.Camara.y -= 5;
    // } else if (this.cursors.down.isDown) {
    //     this.Camara.y += 5;
    // }

    // // Activar la transición cuando se presiona la barra espaciadora
    // if (Phaser.Input.Keyboard.JustDown(this.cursors.space)) {
    //     this.cameraTween.play();
    // }
  }
} 