
<br>
<div class="text-center titulo" style="border-bottom: 1px solid #94cef9;">
    Recursos de apoyo
</div>
<div class="" style="text-align: center;padding: 0px 30px;    padding-top: 15px;background-color: #94cef93b;">
    <div class="row">
        <div *ngFor="let recurso of recursos">
            <div style="padding: 7px;" *ngIf="recurso.plataforma.includes(api.OrigenIframe)" class="hidden-xs hidden-sm col-xs-3 col-sm-3 col-md-2 pulso-css elemento recursos" [style.opacity]="recurso.bloqueado ? 0.3 : 1">
                <div class="cuadro">
                    <span class="nuevo" *ngIf="recurso.nuevo">¡Nuevo!</span>
                    <div style="margin-top: 6px;text-align: right;margin-right: 7px;position: absolute;right: 10px;">
                        <img *ngIf="!recurso.bloqueado" (click)="modal_ayuda(recurso.nombre,recurso.instruccion,recurso.id,recurso.url,recurso.externo,recurso.modal)" src="assets/images/abecedario/ayuda.svg" alt="" style="width: 20px">
                        <!-- <img *ngIf="!recurso.bloqueado" (click)="enviar_sugerencia(recurso.nombre,recurso.url)" src="assets/images/abecedario/comentarios.svg" alt="" style="width: 30px;margin-left: 5px;"> -->
                    </div>
                    <!-- <div class="texto"><label for="">{{recurso.nombre}}</label></div> -->
                    <img *ngIf="recurso.bloqueado" (click)="modal_proximamente(recurso.nombre,recurso.instruccion,recurso.id,recurso.url,recurso.externo,recurso.modal)" src="assets/recursos/{{recurso.src}}.png" alt="" style="width: 80%;">
                    <img *ngIf="!recurso.bloqueado" (click)="ir(recurso.id)" src="assets/recursos/{{recurso.src}}.png" alt="" style="width: 80%;">
                </div>
            </div>
            <div style="padding: 7px;" *ngIf="recurso.plataforma.includes(api.OrigenIframe)" class="hidden-md hidden-lg col-xs-3 col-sm-3 col-md-2 pulso-css elemento recursos" [style.opacity]="recurso.bloqueado ? 0.3 : 1">
                <div class="cuadro">
                    <span class="nuevo" *ngIf="recurso.nuevo">¡Nuevo!</span>
                    <div style="margin-top: 6px;text-align: right;margin-right: 7px;position: absolute;right: 10px;">
                        <img *ngIf="!recurso.bloqueado" (click)="modal_ayuda(recurso.nombre,recurso.instruccion,recurso.id,recurso.url,recurso.externo,recurso.modal)"src="assets/images/abecedario/ayuda.svg" alt="" style="width: 20px">
                        <!-- <img *ngIf="!recurso.bloqueado" (click)="ir(recurso.id)" src="assets/images/abecedario/comentarios.svg" alt="" style="width: 30px;margin-left: 5px;"> -->
                    </div>
                    <!-- <div class="texto"><label for="">{{recurso.nombre}}</label></div> -->
                    <img *ngIf="recurso.bloqueado" (click)="modal_proximamente(recurso.nombre,recurso.instruccion,recurso.id,recurso.url,recurso.externo,recurso.modal)" src="assets/recursos/{{recurso.src}}.png" alt="" style="width: 80%;">
                    <img *ngIf="!recurso.bloqueado" (click)="ir(recurso.id)" src="assets/recursos/{{recurso.src}}.png" alt="" style="width: 80%;">
                </div>
            </div>
        </div>        
    </div>
</div>
<div id="modal-recursos" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" style="margin-top: 100px" role="document">
        <div class="modal-content" style="    border: 7px solid #0cb8df;">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>{{api.TituloModal}}</div>
            </div>
            <div class="modal-body text-center row" style="margin: 0;display: flex;">
                <div *ngFor="let dato of this.datos_modal" style="margin:4px">
                    <div *ngIf="dato.plataforma.includes(api.OrigenIframe)" class="pulso-css">
                        <div  class="cuadro">
                            <div style="margin-top: 6px;text-align: right;margin-right: 7px;">
                                <img *ngIf="!dato.bloqueado" (click)="modal_ayuda(dato.nombre,dato.instruccion,dato.id,dato.url,dato.externo,dato.modal)" src="assets/images/abecedario/ayuda.svg" alt="" style="width: 20px">
                            </div>
                            <img *ngIf="dato.bloqueado" (click)="modal_proximamente(dato.nombre,dato.instruccion,dato.id,dato.url,dato.externo,false)" src="assets/recursos/{{dato.src}}.png" alt="" style="width: 80%;">
                            <img *ngIf="!dato.bloqueado" (click)="ir_modal(dato.url,dato.externo,dato.nombre)" src="assets/recursos/{{dato.src}}.png" alt="" style="width: 80%;">
                            <!-- <i *ngIf="!dato.bloqueado" class="ayuda icon-help" (click)="modal_ayuda(dato.nombre,dato.instruccion,dato.id,dato.url,dato.externo,false)" style="cursor:pointer;color: #44c1f2; font-size: 20px;"></i> -->
                        </div>
                        <!-- <div class="texto"><label for="">{{dato.nombre}}</label></div> -->
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
<div id="modal-ayuda" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" style="margin-top: 100px" role="document">
        <div class="modal-content">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="titulo">{{Titulo_actual}}</div>
            </div>
            <div class="modal-body text-center" style="font-size: 20px;margin: 60px 0px;">
                {{Instruccion_actual}}
            </div>
        </div>
    </div>
</div>
<!-- <div id="modal-cargando" class="jquery-loading-modal jquery-loading-modal" style="z-index: 9899999;">
    <div id="bg-modal-cargando-mg" class="jquery-loading-modal__bg bg-cargando-normal" style="opacity: 1;">
    </div>
    <div class="jquery-loading-modal__info-box">
      <div class="jquery-loading-modal__animation">
      </div>
      <div class="jquery-loading-modal__text" style="color: rgb(55, 57, 91);">
        <div *ngIf="api.OrigenIframe == 'localhost'" class="fa-spin"><img draggable=false src="/assets/inclutics/cargando_in.png" style="width: 70px;"></div>
        <div *ngIf="api.OrigenIframe == 'inclutics'" class="fa-spin"><img draggable=false src="/assets/inclutics/cargando_in.png" style="width: 70px;"></div>
        <div *ngIf="api.OrigenIframe == 'juegoeduca'"><img draggable=false src="/assets/images/cargando_je.gif" style="width: 200px;"></div>
        <div *ngIf="api.OrigenIframe == 'letrapps'"><img draggable=false src="/assets/images/cargando_lt.gif" style="width: 200px;"></div>
        <div *ngIf="api.OrigenIframe == 'tabulatest'"><img draggable=false src="/assets/images/cargando_TBT.gif" style="width: 60px;"></div>
        <div id="mensaje-pdf" class="hidden">
            Preparando PDF...
            <br>
            <div class="cantidad-pdf"></div>
        </div>
      </div>
    </div>
</div> -->

