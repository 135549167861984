<div class="hidden main-app">
	<img class="btn-hover-lsch" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;">
	<img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Nube de Palabras','extras/sopa-de-silabas')" style="left: 8px;top: 65px;">
	<img class="btn-hover efecto-destacado" *ngIf="!global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.valoraciones('Nube de Palabras','extras/sopa-de-silabas')" style="top: 14px;">
</div>
<div id="portada" *ngIf="Portada" @salidaHaciaArriba>
    <div class="row" style="display: flex;flex-wrap: wrap;height: 100%;align-items: center;    justify-content: center;">
        <div class="col-xs-6 col-sm-6 col-md-6 text-center">
            <br>
            <img src="assets/images/aplicativos/nube/descarga_{{IMG_PORTADA}}.png" style="width: 100%;">

            <!-- <img src="assets/images/aplicativos/nube/fondo.svg" style="width: 66vh;"> -->
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 text-center">   
            <img src="assets/images/aplicativos/nube/fondo.svg" style="width: 50%;">
            <br><br><br><br>
            <div style="font-family: 'Lato_Black';font-size: 30px;color: #00695a">Este recurso te permitirá crear gráficos de Nube de Palabras con diversas configuraciones.</div><br>
            <img class="pulso-css" src="assets/images/aplicativos/nube/btn_crear.svg" (click)="iniciar()" style="width: 50%;cursor: pointer;">
        </div>
        <div class="col-sm-12 col-md-12 text-center">
            <div class="text-center bienvenida" style="margin-top: 20px;margin-bottom: 10px;">
                <img src="assets/images/aplicativos/ruidometro/logos.png" style="width: 30%;">
                <br>
                Un producto con ❤ de integritic.cl
            </div>
        </div>
    </div>
</div>
<div class="container" style="margin-top: 50px;">
    <div class="title">Nube de palabras</div>
</div>
<div class="row contenedor hidden">
    <div class="col-sm-12 col-md-3">
        <div class="col-sm-12 mt-15">
            <div>Tipo de texto:</div>
            <select class="select form-control" id="tipo_palabras" [(ngModel)]="TipoPalabra" (change)="changeVariableTipoEntrada()" required>
                <option value="Seleccione" selected disabled>Seleccione tipo</option>
                <option value="CURSO" *ngIf="global.isIframe">Nombres Estudiantes</option>
                <option value="PALABRAS">Palabras</option>
                <option value="LECTURAS">Lecturas</option>                
                <option value="PERSONALIZADO-PALABRAS">Ingresar Palabras Personalizadas</option>
                <option value="PERSONALIZADO-TEXTO">Ingresar Lectura Personalizada</option>
            </select>
            <div class="text-center" style="margin-top: 10px;">
                <button *ngIf="this.ListadoPalabras.length > 0 && TipoPalabra == 'PERSONALIZADO-TEXTO'" style="margin-right: 2px;" class="btn btn-verde" title="Editar Lectura" (click)="ingresarLectura()"><i class="icon-pencil"></i></button>
                <button *ngIf="this.ListadoPalabras.length > 0 && TipoPalabra == 'PERSONALIZADO-TEXTO'" class="btn btn-info" title="Ver Lectura" (click)="AbrirLectura()"><i class="icon-book"></i></button>
            </div>
        </div>
        <div class="col-sm-12" style="margin-top: 3px;" *ngIf="TipoPalabra == 'Seleccione' && TipoPalabra != 'CURSO'">
            <div class="col-sm-7 no-padding">
                Cantidad de Palabras:
            </div>
            <div class="col-sm-5 no-padding">
                <input class="form-control" style="text-align: center;" [(ngModel)]="CANTIDAD" (change)="validarNumero('CANTIDAD',$event,1,15)" (keyup)="validarNumero('CANTIDAD',$event,1,15)" type="number" value="8" name="CANTIDAD" id="CANTIDAD">
            </div>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'CURSO'">
            <div>Curso:</div>
            <select class="select form-control" [(ngModel)]="inputCursoAlumno" id="cursoAlumno" (change)="changeCursoAlumno()" required>
                <option value="Seleccione" selected disabled>Seleccione curso</option>
                <option *ngFor="let cursoAlumno of this.cursosAlumnos; let i = index" value="{{cursoAlumno._id}}">{{cursoAlumno.NOMBRE}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'CURSO'">
            <div>Letra:</div>
            <select class="select form-control" [(ngModel)]="inputDetalleAlumno" id="detalleAlumno" (change)="changeDetalleAlumno()" required>
                <option value="Seleccione" selected disabled>Seleccione letra</option>
                <option *ngFor="let detalleAlumno of this.cursoAlumnoDetalle; let i = index" value="{{detalleAlumno}}">{{detalleAlumno}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'CURSO'">
            <div>Visualización de nombres:</div>
            <select class="select form-control" [(ngModel)]="inputOpcionAlumno" id="opcionAlumno" (change)="changeOpcionAlumno()" required>
                <option value="Seleccione" selected disabled>Seleccione visualización</option>
                <option value="1">Solo nombre</option>
                <option value="2">Solo apellidos</option>
                <option value="3">Solo apellido paterno</option>
                <option value="4">Solo apellido materno</option>
                <option value="5">Nombre y apellidos</option>
                <option value="6">Nombre completo</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'LECTURAS'">
            <div>Procedencia:</div>
            <select class="select form-control" [(ngModel)]="inputProcedencia" id="procedencia" (change)="changeProcedencia()" required>
                <option value="Seleccione" selected disabled>Seleccione procedencia</option>
                <option *ngFor="let procedencia of this.Procedencias; let i = index" value="{{procedencia.value}}">{{procedencia.text}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'LECTURAS'">
            <div>Curso:</div>
            <select class="select form-control" [(ngModel)]="inputCurso" id="curso" (change)="changeCurso()" required [disabled]="inputProcedencia == 'Seleccione' ? true : false">
                <option value="Seleccione" selected disabled>Seleccione curso</option>
                <option *ngFor="let curso of this.Cursos; let i = index" value="{{curso}}">{{curso}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'LECTURAS'">
            <div>Nombre de la lectura:</div>
            <div style="display: flex;">
                <select class="select form-control" [(ngModel)]="inputLectura" id="lectura" (change)="changeLectura()" required [disabled]="inputCurso == 'Seleccione' ? true : false">
                    <option value="Seleccione" selected disabled>Seleccione lectura</option>
                    <option *ngFor="let lectura of this.FiltroLecturas; let i = index" value="{{lectura._id}}">{{lectura.TITULO}} • {{lectura.CLASIFICACION}}</option>
                </select>
                <button *ngIf="inputLectura != ''" class="btn btn-primary" title="Ver Lectura" (click)="AbrirLectura()"><i class="icon-book"></i></button>
            </div>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'PALABRAS'">
            <div>Categorías Semánticas:</div>
            <select class="select form-control" [(ngModel)]="inputCategoria" id="categorias" (change)="changeCategoria($event)" required>
                <option value="Seleccione" selected disabled>Seleccione categoría</option>
                <option *ngFor="let categoria of this.CategoriasPalabras; let i = index" value="{{categoria.CATEGORIA}}">{{categoria.CATEGORIA}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra != 'CURSO'">
            <div>Palabras:</div>
            <div class="form-control contenedor-palabras">
                <div class="palabra" *ngFor="let palabra of this.ListadoPalabras; let i = index" data-id="{{palabra.PALABRA}}">
                    <span class="icon-cross" (click)="eliminarListado(i)"></span>{{i+1}}.- {{palabra.PALABRA}}
                </div>
            </div>
            <br>
            <div class="text-center">
                <button class="btn btn-verde" style="margin-right: 2px" title="Agregar palabra personalizada" (click)="ingresarPalabras()"><i class="icon-plus3"></i></button>
                <button *ngIf="this.TipoPalabra != 'PERSONALIZADO-PALABRAS' && this.TipoPalabra != 'Seleccione'" class="btn btn-info" style="margin-right: 2px" title="Palabras aleatorias" (click)="SeleccionarPalabras()"><i class="icon-spinner11"></i></button>
                <button *ngIf="ListadoPalabras.length != 0" class="btn btn-danger" title="Limpiar selección" (click)="LimpiarListado()"><i class="icon-trash"></i></button>
            </div>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'CURSO'">
            <div>Estudiantes: <b>({{contadorAlumnos}} / {{totalAlumnos}} Seleccionados)</b></div>
            <div class="form-control contenedor-palabras">
                <div class="palabra" *ngFor="let alumno of this.ListadoPalabras; let i = index" data-id="{{alumno.PALABRA}}">
                    <input type="checkbox" style="margin-right: 10px;" id="check-{{alumno.ID}}" [checked]="alumno.checked" (click)="uncheckAlumno(alumno.ID)">
                    <b >{{alumno.N_LISTA}}.- {{alumno.PALABRA}}</b>
                </div>
            </div>
            <br>
            <div class="text-center">
                <button class="btn btn-warning" style="margin-right: 2px" title="Agregar palabra personalizada" (click)="quitarAlumnos()"><i class="icon-user-minus"></i></button>
                <button class="btn btn-verde" style="margin-right: 2px" title="Agregar palabra personalizada" (click)="seleccionarAlumnos()"><i class="icon-user-plus"></i></button>
                <button *ngIf="ListadoPalabras.length != 0" class="btn btn-danger" title="Limpiar selección" (click)="LimpiarListado()"><i class="icon-trash"></i></button>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 text-center central" style="display: flex;align-items: center;justify-content: center;flex-direction: column;min-height: 70vh;">
        <div *ngIf="!InstruccionInicial" id="sopaDeLetras">
            <img id="imgNube" src="{{URL_IMG}}" style="width: 100%;background-color: white;">
        </div>
        <div *ngIf="InstruccionInicial" id="sopaDeLetras">
            <img src="assets/images/aplicativos/nube/descarga_{{IMG_PORTADA}}.png" style="width: 60%;">
            <br>
            <div style="font-family: 'Lato_Regular';font-size: 30px;color: #00695a">Para crear una Nube de Palabras, selecciona textos o palabras, luego personaliza y presiona <b>"Generar Nube"</b></div><br>
        </div>
    </div>
    <div class="col-sm-12 col-md-3">
        <div class="subtitulo">Configuración</div>
        <br>
        <div class="text-center" style="display: flex;align-items: center;margin-top: 20px;">
            <!-- <select id="FuenteActual" [(ngModel)]="FuenteActual" (change)="changeConfiguracion()" class="form-control">
                <option value="Arial">Arial</option>
                <option value="Tahoma">Tahoma</option>
                <option *ngIf="isMayuscula != 'mayuscula'" value="Ligada">Escolar Ligada</option>
                <option value="Century">Century</option>
                <option value="Dislexia">Dislexia</option>
                <option value="Lsch">LSCH</option>
            </select> -->
            <div class="text-center">
                <select [(ngModel)]="tipoColor" (change)="changeConfiguracion()" class="form-control">
                    <option value="color_aleatorio">Colores aleatorios</option>
                    <option value="color_personalizable">Color personalizado</option>
                </select>
            </div>
            <button [class]="isMayuscula == 'mayuscula' ? 'btn btn-info' : 'btn btn-grey'" (click)="cambiarMayuscula('mayuscula')" title="Mayúscula" style="margin: 2px;padding: 1px 5px;"><img src="assets/images/aplicativos/sopa-silabas/font-1.png"></button>
            <button [class]="isMayuscula == 'mayuscula' ? 'btn btn-grey' : 'btn btn-info'" (click)="cambiarMayuscula('minuscula')" title="Minúscula" style="margin: 2px;padding: 1px 5px;"><img src="assets/images/aplicativos/sopa-silabas/font-2.png"></button><i class="fa fa-question-circle" (click)="mensaje_info('Configuración de la fuente')" style="font-size: 20px;cursor: pointer;color: #4392cc;margin-left: 3px;"></i>
        </div>
        <div class="text-center" style="margin-top: 20px;" *ngIf="tipoColor == 'color_personalizable'">
            <label for="colorPicker" style="margin-right: 30px;">Color del texto:</label>
            <input type="color" id="colorPicker" [(ngModel)]="ColorActual" />
        </div>
        <div style="margin-top: 20px;" *ngIf="ListadoPalabras.length != 0">
            <div>Categorías diseños:</div>
            <select id="CategoriaSombraActual" [(ngModel)]="CategoriaSombraActual" (change)="changeSombras()" class="form-control">
                <option value="formas_geometricas">Formas Geométricas</option>
                <option value="animales">Animales</option>
                <option value="numeros">Números</option>
                <option value="otras_formas">Otras Formas</option>
                <option value="pokemon">Pokemón</option>
                <option value="navidad">Navidad</option>
                <option value="halloween">Halloween</option>
                <option value="todas">Todas</option>
            </select>
        </div>
        <div style="margin-top: 20px;" *ngIf="ListadoPalabras.length != 0">
            <div>Diseños:</div>
            <div class="text-center" style="    border: 1.5px solid #00af9b !important;border-radius: 4px;height: 300px;overflow-y: scroll;padding: 1px;">
                <img *ngFor="let sombra of this.sombras[this.CategoriaSombraActual]; let i = index" src="https://cdn-integritic.s3.amazonaws.com/formas-nube/{{sombra.id}}.png" (click)="setSombra(sombra.id)" [class]="this.FormaActual == sombra.id ? 'sombra-select' : 'sombras'">
            </div>
        </div>
        <div style="margin-top: 20px;">
            <div>Orientación palabras:</div>
            <select id="OrientacionActual" [(ngModel)]="OrientacionActual" (change)="changeConfiguracion()" class="form-control">
                <option value="1">Horizontal</option>
                <option value="0">Vertical</option>
                <option value="0.5">Ambos Sentidos</option>
            </select>
        </div>
        <!-- <div class="text-center" style="margin-top: 20px;">
            <select [(ngModel)]="tipoColor" (change)="changeConfiguracion()" class="form-control">
                <option value="color_aleatorio">Aleatorio</option>
                <option value="color_personalizable">Personalizado</option>
            </select>
        </div> -->
        <!-- <div class="flex" style="justify-content: center;margin-top: 20px;">
            <div style="margin-left: 0;"> Aleatorio</div>
            <label class="" style="margin-left: 5px;">
                <input type="radio" name="tipoColor" (click)="changeConfiguracion()" [ngModel]="tipoColor" value="color_aleatorio" checked>
            </label>
            <div style="margin-left: 20px;"> Personalizado</div>
            <label class="" style="margin-left: 5px;">
                <input type="radio" name="tipoColor" (click)="changeConfiguracion()" [ngModel]="tipoColor" value="color_personalizable">
            </label>
        </div> -->
        <br>
        <div class="text-center">
            <button class="btn {{claseBtn}}" id="boton-generar" (click)="GenerarGrafico()" title="Generar Gráfico" style="margin-top: 20px;font-size: 3vh;font-family: 'Lato_Bold';border-radius: 80px;" [disabled]="ListadoPalabras.length == 0 ? true : false"><i class="icon-spinner11" style="margin-right: 5px;font-size: 3vh;"></i> Generar Nube</button>
            <br><br>
        </div>
        <div *ngIf="GraficoCreado">
            <div class="subtitulo">Descarga</div>
            <br>
            <div class="text-center">
                <button class="btn btn-verde2" (click)="generateImage()" style="margin-right: 3px;"><i class="icon-image2" style="margin-right: 5px;"></i> Imagen</button>
                <button class="btn btn-verde2" (click)="generatePDF()" style="margin-right: 3px;"><i class="icon-file-pdf" style="margin-right: 5px;"></i> PDF</button>
                <button class="btn btn-verde2" (click)="generateDocx()"><i class="icon-file-word" style="margin-right: 5px;"></i> Word</button>
            </div>
        </div>
        <br>
    </div>
</div>
<div id="modal-lectura" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="borrarSeleccion()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="font-size: 30px"><b>{{inputTitulo}}</b></h5>
            </div>
            <div class="modal-body text-center" style="padding: 40px;font-size: 20px;text-align: justify;">
                {{inputTexto}}
            </div>
        </div>
    </div>
</div>
<div id="modal-ingreso-palabras" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="width:100%;font-size: 20px">Ingrese las palabras</h5>
            </div>
            <div class="modal-body text-center">
                <div>
                    Introduce las palabras que deseas añadir una por línea en el cuadro de texto que aparece a continuación.
                </div>
                <br>
                <textarea class="form-control" name="EXTRAS" id="EXTRAS" style="resize: none;" cols="30" rows="15"></textarea>
                <br>
                <button class="btn btn-info" (click)="agregarPalabras()"><i class="icon-plus3" style="margin-right: 5px;"></i> Agregar palabras</button>
            </div>
        </div>
        </div>
    </div>
</div>
<div id="modal-ingreso-lectura" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="borrarSeleccion()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <div id="PasoLectura_1" style="padding: 0px 20px;">
                    <div style="font-size: 30px;margin-bottom: 20px;"><img src="assets/images/aplicativos/sopa-silabas/btn-1.svg" style="width: 40px;margin-right: 5px;margin-top: -7px;"><b>Ingresar Texto:</b></div>
                    <br>
                    Título:
                    <input class="form-control" style="text-align: center;" [(ngModel)]="LECTURA_TITULO" name="LECTURA-TITULO" id="LECTURA-TITULO" maxlength="50" (keyup)="validar_cantidad()">
                    <br>
                    Texto:
                    <textarea class="form-control" [(ngModel)]="LECTURA_TEXTO" name="LECTURA-TEXTO" id="LECTURA-TEXTO" style="resize: none;" cols="30" rows="15" (keyup)="validar_cantidad()"></textarea>
                    <label style="background: #34cd8f;padding: 3px 10px;color: white;font-family: 'Lato_Black';">{{contador_palabras}}</label>
                    <br>
                    <button class="btn btn-danger" (click)="limpiarLectura()" style="margin-right: 2px;"><i class="icon-trash" style="margin-right: 5px;"></i> Limpiar</button>
                    <button class="btn btn-info" (click)="Siguiente()"><i class="fa fa-arrow-right" style="margin-right: 5px;"></i> Siguiente</button>
                </div>
                <div class="hidden" id="PasoLectura_2" style="padding: 0px 20px;">
                    <div style="font-size: 30px;margin-bottom: 20px;"><img src="assets/images/aplicativos/sopa-silabas/btn-2.svg" style="width: 40px;margin-right: 5px;margin-top: -7px;"><b>Selección de palabras:</b></div>
                    <br>
                    <div class="row">
                        <div class="col-sm-12 text-left">Seleccione la palabra que desea utilizar para crear la sopa de letras:</div>
                        <br>
                        <div class="col-sm-8 text-center">
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 50px;line-height: 25px;" id="SPAN-TITULO"></span>
                            <br>
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 380px;line-height: 25px;word-wrap: break-word;" id="SPAN-TEXTO"></span>
                            <label style="background: #34cd8f;padding: 3px 10px;color: white;font-family: 'Lato_Black';">{{contador_palabras}}</label>
                        </div>
                        <div class="col-sm-4">
                            <div class="text-left">Palabras seleccionadas:</div>
                            <div class="form-control contenedor-palabras-texto">
                                <div class="palabra text-left" *ngFor="let palabra of this.ListadoTexto; let i = index"><span class="icon-cross" (click)="eliminarPalabraTexto(i,palabra.INDEX)"></span>{{i+1}}.- {{palabra.PALABRA}}</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-info" (click)="Atras()" style="margin-right: 2px;"><i class="fa fa-arrow-left" style="margin-right: 5px;"></i> Atrás</button>
                    <button class="btn btn-verde" (click)="LecturaProcesada()"><i class="icon-check" style="margin-right: 5px;"></i> Finalizar</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<div id="modal-ingreso-lectura-bd" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="borrarSeleccion()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <div id="PasoLectura_3" style="padding: 0px 20px;">
                    <!-- <img src="assets/images/aplicativos/sopa-silabas/btn-1.svg" style="width: 40px;margin-right: 5px;margin-top: -7px;"> -->
                    <div style="font-size: 30px;margin-bottom: 20px;"><b>Selección de palabras:</b></div>
                    <div class="row">
                        <div class="col-sm-12 text-left">Seleccione la palabra que desea utilizar para crear la sopa de letras:</div>
                        <br>
                        <div class="col-sm-8 text-center">
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 40px;line-height: 25px;" id="SPAN-TITULO-BD"></span>
                            <br>
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 420px;line-height: 25px;word-wrap: break-word;" id="SPAN-TEXTO-BD"></span>
                        </div>
                        <div class="col-sm-4">
                            <div class="text-left">Palabras seleccionadas:</div>
                            <div class="form-control contenedor-palabras-texto">
                                <div class="palabra text-left" *ngFor="let palabra of this.ListadoTexto; let i = index"><span class="icon-cross" (click)="eliminarPalabraTexto(i,palabra.INDEX)"></span>{{i+1}}.- {{palabra.PALABRA}}</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-verde" (click)="LecturaProcesadaBD()"><i class="icon-check" style="margin-right: 5px;"></i> Finalizar</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<!-- <div id="modal-impresion" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="borrarSeleccion()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="width:100%;font-size: 20px">Opciones adicionales</h5>
            </div>
            <div class="modal-body text-center">
                <div>
                    Configura los datos que deseas integrar a tu documento
                </div>
                <br>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkTitulo">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Título:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Título" id="Título" value="Sopa de {{this.tipoSopa ? 'Sílabas' : 'Letras' }}">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkInstruccion">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Instrucciones:</div>
                    <input style="width: 70%;" class="form-control" type="text" maxlength="90" name="Instrucciones" id="Instrucciones">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkNombre">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Nombre:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Nombre" id="Nombre">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkCurso">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Curso:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Curso" id="Curso">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkColegio">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Colegio:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Colegio" id="Colegio" [(ngModel)]="this.DATOS_USUARIO.institucion">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkFecha">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Fecha:</div>
                    <input style="width: 70%;" class="form-control" type="date" name="Fecha" id="Fecha" >
                </div>
                <div class="flex" style="align-items: center">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkDocente">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Docente:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Docente" id="Docente" [(ngModel)]="this.DATOS_USUARIO.usuario">
                </div>
                <br>
                <button class="btn btn-verde" (click)="generatePDF()"><i class="icon-file-pdf" style="margin-right: 5px;"></i> Imprimir</button>
            </div>
        </div>
        </div>
    </div>
</div>  -->
<img id="img-logo-pdf" class="hidden" src="assets/images/logo-largo.png">
<img id="img-logo-pdf-lt" class="hidden" src="assets/images/logo_rojo_definitivo.png">
<img id="img-logo-pdf-tt" class="hidden" src="assets/images/logo_tt.png">