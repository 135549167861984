import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { ApiService } from 'src/app/inicio/services/api.service';
import { GlobalService } from 'src/app/inicio/services/global.service';
import ObjectID from "bson-objectid"
declare var $: any;

@Component({
  selector: 'app-recursos',
  templateUrl: './recursos.component.html',
  styleUrls: ['./recursos.component.css']
})
export class RecursosComponent implements OnInit {
  recursos: any;
  datos_modal:any = [];
  alerta:any;
  recursos_id_inst = "";
  recursos_prom = "";
  recursos_id_us = "";
  recursos_plataforma = "";
  audioClick:any = new Audio('assets/recursos/click.mp3');
  audioHover:any = new Audio('assets/recursos/hover.mp3');
  constructor(public api: ApiService, private router: Router,private route: ActivatedRoute, public global: GlobalService) {
    this.recursos = this.api.recursos;
    this.alerta = this.global.alerta;
    $("body").css("background-color", "#fff8ec!important");
    this.route.params.subscribe((params:any) => {
      this.recursos_id_inst = params.id_institucion
      this.recursos_prom = params.promocion
      this.recursos_id_us = params.usuario
      this.recursos_plataforma = params.plataforma
    });
    $('#modal-cargando').addClass('hidden');
    $('#modalaso').addClass('hidden');
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   $('#modal-cargando').addClass('hidden');
    //   $('#modalaso').addClass('hidden');
    // }, 1500);    
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.type === 'navigate') {
      this.navigate(event.data.url);
    }
  }
  // @HostListener('window:resize', ['$event'])
  ajustarTamano(){
    let alturaMasAlta = 0;
    $('.recursos').each(function() {
        var alturaElemento = $(this).height();
        if (alturaElemento > alturaMasAlta) {
            alturaMasAlta = alturaElemento;
        }
        $('.recursos').css('height',(alturaMasAlta)+'px')
    });
  }
  ir(id) {
    this.audioClick.play();
    setTimeout(() => {
      this.datos_modal = [];
      let recurso = this.recursos.filter(A=> A.id == id)[0];
      if(recurso.modal){
        this.datos_modal = recurso.datos_modal;
        $('#modal-recursos').modal();
        if(recurso.nombre == "Temporizador"){
          this.api.TituloModal = "Seleccionar Temporizador";
        }else{
          this.api.TituloModal = "Seleccione";
        }
      }else{
        if (recurso.externo) {
          // Comunicar al contenedor para que realice la redirección
          window.parent.postMessage({ type: 'navigate', url: recurso.url, nombre: recurso.nombre, accion: recurso.nombre }, '*');
        } else {
          if(recurso.variables){
            console.log(recurso.nombre)
            window.parent.postMessage({ type: 'kpi',nombre: recurso.nombre, accion: recurso.nombre}, '*');
            if(recurso.nombre === "Cumpleaños"){
              window.location.href = '/' + recurso.url + this.recursos_id_inst+("HOY_"+new Date().toLocaleDateString('es-CL').split("-").reverse().join("-").replaceAll("-","_")) + '/' +this.recursos_prom+'/'+this.recursos_plataforma; //AÑADI LA PLATAFORMA COMO PARAMETRO PARA SABER SI ES B2C O NO
            }else if(recurso.nombre === "Sopa de Sílabas y Letras"){
              window.location.href = '/' + recurso.url +'/'+ this.recursos_id_inst + '/' +this.recursos_prom + '/' +this.recursos_id_us;
            }else if(recurso.nombre === "Generador de Historias"){
              window.location.href = '/' + recurso.url +'/'+ this.recursos_id_inst + '/' +this.recursos_prom + '/' +this.recursos_id_us+ '/' +this.recursos_plataforma; //AÑADI LA PLATAFORMA COMO PARAMETRO PARA SABER SI ES B2C O NO;
            }else if(recurso.nombre === "Generador de Nube de Palabras"){
              window.location.href = '/' + recurso.url +'/'+ this.recursos_id_inst + '/' +this.recursos_prom + '/' +this.recursos_id_us+ '/' +this.recursos_plataforma; //AÑADI LA PLATAFORMA COMO PARAMETRO PARA SABER SI ES B2C O NO;
            }else if(recurso.nombre === "Selector de estudiantes"){
              window.location.href = '/' + recurso.url +'/'+ this.recursos_id_inst + '/' +this.recursos_prom + '/' +this.recursos_plataforma; //AÑADI LA PLATAFORMA COMO PARAMETRO PARA SABER SI ES B2C O NO;
            }
            else{
              window.location.href = '/' + recurso.url +'/'+ this.recursos_id_inst + '/' +this.recursos_prom;
            }          
          }else{
            window.parent.postMessage({ type: 'kpi', nombre: recurso.nombre, accion: recurso.nombre}, '*');
            window.location.href = '/' + recurso.url;
          }
          // this.navigate('/' + recurso.url);
        }
    }
    }, 800);
  }
  ir_modal(ruta,externo,nombre) {
    this.audioClick.play();
    setTimeout(() => {
      if (externo) {
        // Comunicar al contenedor para que realice la redirección
        window.parent.postMessage({ type: 'navigate', url: ruta, nombre: nombre}, '*');
      } else {
        window.parent.postMessage({ type: 'kpi', nombre: nombre, accion: nombre}, '*');
        window.location.href = '/' + ruta;
      }
      $('#modal-recursos').modal('hide');
    }, 800);
  }
  private navigate(url: string) {
    this.router.navigateByUrl(url, { skipLocationChange: false }).then(() => this.router.navigate([url]));
  }
  Instruccion_actual:any = "";
  Titulo_actual:any = "";
  modal_ayuda(titulo,instruccion,id,url,externo,modal){
    this.audioClick.play();
    // $('#modal-ayuda').modal();
    this.Instruccion_actual = instruccion;
    this.Titulo_actual = titulo;
    this.alerta.fire({
      title: titulo,
      text: instruccion,
      icon: "info",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Ir al recurso",
      cancelButtonText: "Cerrar",
      customClass: {
        popup: 'popup-scale',
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if(result.isConfirmed){
        if(modal){
          this.ir(id);
        }else{
          this.ir_modal(url,externo,titulo);
        }
      }
    });
    $('.popup-scale').css('scale', '1.5');
  }
  modal_proximamente(titulo,instruccion,id,url,externo,modal){
    this.alerta.fire({
      title: "¡Próximamente "+titulo+"!",
      text: instruccion,
      icon: "info",
      showDenyButton: false,
      showCancelButton: false,
      cancelButtonText: "Ok",
      customClass: {
        popup: 'popup-scale',
      },
    })
    $('.popup-scale').css('scale', '1.5');
  }
  playAudio() {
    console.log('playAudio')
    this.audioHover.play();
  }
  stopAudio() {
    this.audioHover.pause();
    this.audioHover.currentTime = 0;
  }
}