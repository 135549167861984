import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
declare var $: any;

// import { HomeComponent } from './inicio/home/home.component';
import { RuidometroComponent } from './aplicativos/ruidometro/ruidometro.component';
import { RespirometroComponent } from './aplicativos/respirometro/respirometro.component';
import { SelectorEstudiantesComponent } from './aplicativos/selector-estudiantes/selector-estudiantes.component';
import { SaludoCumpleanosComponent } from './aplicativos/saludo-cumpleanos/saludo-cumpleanos.component';

import { TemporizadorComponent } from './aplicativos/temporizador/temporizador.component';

import { BoardComponent } from './aplicativos/board/board.component';
import { RecursosComponent } from './aplicativos/recursos/recursos.component';
import { TemporizadorVisualComponent } from './aplicativos/temporizador-visual/temporizador-visual.component';
import { TemporizadorEmojisComponent } from './aplicativos/temporizador-emojis/temporizador-emojis.component';
import { TemporizadorSensorialComponent } from './aplicativos/temporizador-sensorial/temporizador-sensorial.component';
import { SopaSilabasComponent } from './aplicativos/sopa-silabas/sopa-silabas.component';
import { LandingTemporizadoresComponent } from './aplicativos/landing-temporizadores/landing-temporizadores.component';
import { GeneradorHistoriasComponent } from './aplicativos/generador-historias/generador-historias.component';
import { NubePalabrasComponent } from './aplicativos/nube-palabras/nube-palabras.component';



// $('body').loadingModal({
//    position:'auto',
//    text:'Cargando...',
//    color:'#37395b',
//    opacity:'1',
//    backgroundColor:'#fdefd3',
//    animation:'chasingDots'
//  });


$('#modalaso').show();

const routes: any = [
  {
    path: 'extras',
    opciones:{
      url: 'ruidometro',
    },
    // component: LayoutComponent,
    // canActivateChild: [AuthGuard],
    children: [
      { path: 'ruidometro', component: RuidometroComponent },
      { path: 'respirometro', component: RespirometroComponent },
      { path: 'board', children: [{path: ':id', component: BoardComponent}] },
      { path: 'selector-estudiantes', children: [{path: ':institucion/:promocion/:plataforma', component: SelectorEstudiantesComponent}] },
      { path: 'saludo-cumpleanos', children: [{path: ':institucion/:promocion/:plataforma', component: SaludoCumpleanosComponent}] },
      { path: 'temporizadores', component: LandingTemporizadoresComponent },
      { path: 'temporizador', component: TemporizadorComponent },
      { path: 'temporizadorvisual', component: TemporizadorVisualComponent },
      { path: 'temporizadoremojis', component: TemporizadorEmojisComponent },
      { path: 'temporizadorsensorial', component: TemporizadorSensorialComponent },
      { path: 'generador-historias', component: GeneradorHistoriasComponent },
      { path: 'generador-historias', children: [{path: ':institucion/:promocion/:usuario', component: GeneradorHistoriasComponent}] },
      { path: 'generador-historias', children: [{path: ':institucion/:promocion/:usuario/:plataforma', component: GeneradorHistoriasComponent}] },
      { path: 'sopa-de-silabas', component: SopaSilabasComponent },
      { path: 'sopa-de-silabas', children: [{path: ':institucion/:promocion/:usuario', component: SopaSilabasComponent}] },
      { path: 'nube-palabras', component: NubePalabrasComponent },
      { path: 'nube-palabras', children: [{path: ':institucion/:promocion/:usuario', component: NubePalabrasComponent}] },
      { path: 'nube-palabras', children: [{path: ':institucion/:promocion/:usuario/:plataforma', component: NubePalabrasComponent}] },
      
      // { path: 'h', component: LandingJuegoeducaComponent },
      // { path: 'inicio', component: HomeComponent },
      // { path: 'ingreso-texto', component: IngresoTextoComponent },
      // { path: 'leo-primero', component: LeoPrimeroComponent },
      // { path: 'seleccion-palabras-categorias', component: SeleccionPalabrasCategoriasComponent },
      // { path: 'pop-it', component: PopItComponent },
      // { path: 'sopa-de-letras', component: SopaDeLetrasComponent },
      // { path: 'ahorcado', component: AhorcadoComponent },
      // { path: 'desarmado', component: AhorcadoComponent },
      // { path: 'zombiletras', component: AhorcadoHalloweenComponent },
      // { path: 'juego-separa-palabra-silabas', component: JuegoSeparaPalabraSilabasComponent },
      // { path: 'juego-uso-letras', component: JuegoUsoLetrasComponent },
      // { path: 'ruleta', component: RuletaComponent },
      // { path: 'tomboletras', component: TomboletrasComponent },
      // { path: 'maquina-de-lecturas', component: MaquinaDeLecturasComponent },
      // { path: 'seleccion-juegos', component: SeleccionJuegosComponent },
      // { path: 'seleccion-juegos-je', component: SeleccionJuegosJeComponent },
    ], 
  },
  {
    path: 'RecursosApoyo',
    opciones:{
      url: 'RecursosApoyo',
      titulo: "Método Global",
      juego: '/RecursosApoyo',
    },
    // component: LayoutComponent,
    // canActivateChild: [AuthGuard],
    children: [
      { path: '', component: RecursosComponent },
      { path: ':id_institucion/:promocion/:usuario/:plataforma', component: RecursosComponent },
    ], 
  },
  {
     path: '',
     loadChildren: ()=> import("./inicio/inicio.module").then(e=>{
      // 
       return new Promise(resolve =>{
          
          let contador=0;
          /*let interval = setInterval(()=>{
             BarraProgreso.set(++contador);
             if(contador===100) clearInterval(interval);
          },15)*/



         setTimeout(()=>{
            resolve(e.InicioModule);
            setTimeout(()=>{
              $('#modalaso').hide()
              // setTimeout(()=>$('#modalaso').removeClass('jquery-loading-modal--visible').addClass('jquery-loading-modal--hidden'),1500);
               
            },0);

         },0);
       });
     })
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
